<div [ngClass]="{'dark-theme': darkMode}" style="position: relative;margin: 0 auto;">
	<!-- <mat-toolbar color="primary" class="title mat-elevation-z6">
        Code Beautifier
        <mat-slide-toggle class="theme-toggle" [(ngModel)]="darkMode" (change)="setLocalStorage('darkMode', darkMode)">
            Dark Mode
        </mat-slide-toggle>
        <button mat-raised-button color="warn" class="reset" (click)="clearStorageOptions()" style="text-transform: uppercase;">
            Reset Storage
        </button>
	</mat-toolbar> -->
	<!-- <div id="selections-language" class="source-div">
		<mat-select class="select" [(ngModel)]="selectedLanguageType" (ngModelChange)="changeLanguageType()" 
			panelClass="{{darkMode ? 'dark-theme' : ''}}" name="language" id="language">
			<mat-option value="css">Beautify CSS</mat-option>
			<mat-option value="html">Beautify HTML</mat-option>
            <mat-option value="js">Beautify JavaScript</mat-option>
			<mat-option value="json">Beautify JSON</mat-option>
			<mat-option value="sql">Beautify SQL</mat-option>
		</mat-select>
	</div> -->
	<!-- <div id="selections-table" class="source-div">
		<mat-expansion-panel>
			<mat-expansion-panel-header>
				<mat-panel-title>
					{{selectedLanguageType === 'sql' ? 'SQL Options' : 'Advanced Options'}}
				</mat-panel-title>
            </mat-expansion-panel-header>
            <table id="selections-table" class="table sql" *ngIf="selectedLanguageType === 'sql'">
				<tr>
                    <td>
                        <p>Language Type</p>
                        <mat-select class="select" [(ngModel)]="sqlOptions['language']" panelClass="{{darkMode ? 'dark-theme' : ''}}" id="sql-type">
                            <mat-option value="sql">Standard SQL</mat-option>
                            <mat-option value="n1ql">Couchbase N1QL</mat-option>
                            <mat-option value="db2">IBM DB2</mat-option>
                            <mat-option value="pl/sql">Oracle PL/SQL</mat-option>
                        </mat-select>
                        <br>
                    </td>
                    <td>
                    	<p>Intendation Size [Default Value is 4]</p>
                        <mat-slider min="0" max="32" step="2" thumbLabel tickInterval="2" [(ngModel)]="sqlOptions['indentation']" 
                        	name="tabsize" id="tabsize">
						</mat-slider>
                    </td>
                    <td>
                    	<p style="margin-bottom: 20px;">Options</p>
						<mat-checkbox [(ngModel)]="sqlOptions['upperCase']">
							Convert to UpperCase
						</mat-checkbox>
                    </td>
                </tr>
            </table>
			<table id="selections-table" class="table" *ngIf="selectedLanguageType !== 'sql'">
				<tr>
					<td>
						<p>Tab Size [Default Value is 4]</p>
						<mat-checkbox [(ngModel)]="options['indent_with_tabs']">
							Intend with tab
						</mat-checkbox>
                        <mat-slider min="4" max="32" step="2" thumbLabel tickInterval="2" [disabled]="options['indent_with_tabs']" 
                        [(ngModel)]="options['indent_with_tabs'] ? 4 : options['indent_size']" name="tabsize" id="tabsize">
						</mat-slider>
						<br>
						<p>New Line</p>
						<mat-checkbox [(ngModel)]="options['preserve_newlines']">
							Preserve new lines
						</mat-checkbox>
                        <mat-slider min="0" max="10" step="1" thumbLabel tickInterval="1" [disabled]="!options['preserve_newlines']" 
                        [(ngModel)]="options['max_preserve_newlines']" name="max-preserve-newlines" id="max-preserve-newlines">
						</mat-slider>
						<br>
						<p>Wrap lines that exceed N characters [Default Value is 0]</p>
                        <mat-slider min="0" max="160" step="10" thumbLabel tickInterval="10" [(ngModel)]="options['wrap_line_length']" 
                        name="wrap-line-length" id="wrap-line-length">
						</mat-slider>
                        <br>
						<p>Initial Indentation Level [Default Value is 0]</p>
                        <mat-slider min="0" max="100" step="10" thumbLabel tickInterval="10" [(ngModel)]="options['indent_level']" 
                        name="indent-level-length" id="indent-level-length">
                        </mat-slider>
                        <br>
						<p>HTML &lt;style&gt;, &lt;script&gt; formatting:</p>
						<mat-select class="select" [(ngModel)]="options['indent_scripts']" panelClass="{{darkMode ? 'dark-theme' : ''}}" id="indent-scripts">
							<mat-option value="keep">Keep indent level of the tag</mat-option>
							<mat-option value="normal">Add one indent level</mat-option>
							<mat-option value="separate">Separate indentation</mat-option>
                        </mat-select>
                        <br>
                        <br>
						<p>Brace Styles</p>
						<mat-select class="select" [(ngModel)]="options['brace_style']" panelClass="{{darkMode ? 'dark-theme' : ''}}" id="brace-style">
							<mat-option value="collapse">Braces with control statement</mat-option>
							<mat-option value="expand">Braces on own line</mat-option>
							<mat-option value="end-expand">End braces on own line</mat-option>
							<mat-option value="none">Attempt to keep braces where they are</mat-option>
						</mat-select>
                        <br>
                        <br>
						<p>Operator Position</p>
						<mat-select class="select" [(ngModel)]="options['operator_position']" panelClass="{{darkMode ? 'dark-theme' : ''}}" id="operator-style">
							<mat-option value="before-newline">Before Newline</mat-option>
							<mat-option value="after-newline">After Newline</mat-option>
							<mat-option value="preserve-newline">Preserve Newline</mat-option>
                        </mat-select>
					</td>
					<td>
						<p>Options</p>
						<mat-checkbox class="options-checkbox" [(ngModel)]="options['space_in_paren']">
							Add padding spaces within paren, ie. f( a, b )
						</mat-checkbox>
						<br>
						<mat-checkbox class="options-checkbox" [(ngModel)]="options['space_in_empty_paren']">
							Add a single space inside empty paren, ie. f( )
						</mat-checkbox>
						<br>
						<mat-checkbox class="options-checkbox" [(ngModel)]="options['editorconfig']">
							Use EditorConfig to set up the options
						</mat-checkbox>
						<br>
						<mat-checkbox class="options-checkbox" [(ngModel)]="options['space_after_anon_function']">
							Add a space before an anonymous function's parens, ie. function ()
						</mat-checkbox>
						<br>
						<mat-checkbox class="options-checkbox" [(ngModel)]="options['space_after_named_function']">
							Add a space before a named function's parens, i.e. function example ()
						</mat-checkbox>
						<br>
						<mat-checkbox class="options-checkbox" [(ngModel)]="options['e4x']">
							Support e4x/jsx syntax
						</mat-checkbox>
						<br>
						<mat-checkbox class="options-checkbox" [(ngModel)]="options['comma_first']">
							Use comma-first list style?
						</mat-checkbox>
						<br>
						<mat-checkbox class="options-checkbox" [(ngModel)]="options['keep_array_indentation']">
							Keep array indentation?
						</mat-checkbox>
						<br>
						<mat-checkbox class="options-checkbox" [(ngModel)]="options['break_chained_methods']">
							Break lines on chained methods?
						</mat-checkbox>
						<br>
						<mat-checkbox class="options-checkbox" [(ngModel)]="options['space_before_conditional']">
							Space before conditional: "if(x)" / "if (x)"
						</mat-checkbox>
						<br>
						<mat-checkbox class="options-checkbox" [(ngModel)]="options['unescape_strings']">
							Unescape printable chars encoded as \xNN or \uNNNN?
						</mat-checkbox>
						<br>
						<mat-checkbox class="options-checkbox" [(ngModel)]="options['jslint_happy']">
							Use JSLint-happy formatting tweaks?
						</mat-checkbox>
						<br>
						<mat-checkbox class="options-checkbox" [(ngModel)]="options['indent-inner-html']">
							Indent &lt;head&gt; and &lt;body&gt; sections?
						</mat-checkbox>
					</td>
					<td>
						<p>Your Selected Options (JSON):</p>
                        <textarea id="options" spellcheck="false" [ngModel]="options | json" (ngModelChange)="validateJSON($event)" rows="29">
                            {{options | json}}
                        </textarea>
                        <button mat-raised-button color="warn" class="default" (click)="resetDefault()" style="width: 100%;">
                            Reset to Default
                        </button>
					</td>
				</tr>
			</table>
		</mat-expansion-panel>
	</div> -->
	<div class="source-div">
		<div class="form-control d-lg-flex">
			<div class="form-group">
				<select class="form-select {{darkMode ? 'dark-theme' : ''}}" aria-label="Select Language Type" [(ngModel)]="selectedLanguageType" (ngModelChange)="changeLanguageType()" 
				name="language" id="language">
					<option value="css">Beautify CSS</option>
					<option value="html">Beautify HTML</option>
					<option value="js">Beautify JavaScript</option>
					<option value="json">Beautify JSON</option>
					<option value="sql">Beautify SQL</option>
				</select>
			</div>
			<button class="btn custom-file-uploader" title="Import HTML Code">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-folder-plus" viewBox="0 0 16 16">
					<path d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z" />
					<path d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z" />
				</svg>
				<input id="inputfile" type="file" (change)="importCode($event)">
			</button>
			<button class="btn btn-refresh" title="Refresh" (click)="refresh()">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16">
					<path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
					<path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
				</svg>
			</button>
			<button class="btn btn-copy" title="Copy" (click)="copyCode()">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
					<path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
					<path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
				</svg>
			</button>
			<button class="btn btn-download" title="Download Code" (click)="downloadCode()">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
					<path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
				</svg>
			</button>
			<div class="ms-auto d-flex">
				<div class="border border-primary rounded mx-2">
					<button class="mx-2 btn btn-outline-primary btn-sm rounded-circle" data-bs-toggle="modal" data-bs-target="#exampleModal">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
							<path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
							<path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
						</svg>
					</button>
					<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
						<div class="modal-dialog {{selectedLanguageType === 'sql' ? 'modal-lg' : 'modal-xl'}}">
						<div class="modal-content">
							<div class="modal-header">
							<h5 class="modal-title" id="exampleModalLabel">{{selectedLanguageType === 'sql' ? 'SQL Options' : 'Advanced Options'}}</h5>
							<div class="ms-auto">
								<button class="mx-2 btn btn-primary" (click)="beautify()" style="text-transform: uppercase;">
									Beautify {{selectedLanguageType}}
								</button>
								<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							</div>
							<!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
							</div>
							<div class="modal-body" [style.maxHeight]="(modalHeight - headerHeight) + 'px'" style="overflow-y: auto;">
								<table id="selections-table" class="sql" *ngIf="selectedLanguageType === 'sql'">
									<tr>
										<td>
											<p>Language Type</p>
											<select class="form-select" aria-label="Language Type" [(ngModel)]="sqlOptions['language']" panelClass="{{darkMode ? 'dark-theme' : ''}}" id="sql-type">
												<option value="sql">Standard SQL</option>
												<option value="n1ql">Couchbase N1QL</option>
												<option value="db2">IBM DB2</option>
												<option value="pl/sql">Oracle PL/SQL</option>
											</select>
											
											<!-- <mat-select class="select" [(ngModel)]="sqlOptions['language']" panelClass="{{darkMode ? 'dark-theme' : ''}}" id="sql-type">
												<mat-option value="sql">Standard SQL</mat-option>
												<mat-option value="n1ql">Couchbase N1QL</mat-option>
												<mat-option value="db2">IBM DB2</mat-option>
												<mat-option value="pl/sql">Oracle PL/SQL</mat-option>
											</mat-select> -->
											<br>
										</td>
										<td>
											<p>Intendation Size [Default Value is 4]</p>
											<mat-slider min="0" max="32" step="2" thumbLabel tickInterval="2" [(ngModel)]="sqlOptions['indentation']" 
												name="tabsize" id="tabsize">
											</mat-slider>
										</td>
										<td>
											<p style="margin-bottom: 20px;">Options</p>
											<mat-checkbox [(ngModel)]="sqlOptions['upperCase']">
												Convert to UpperCase
											</mat-checkbox>
										</td>
									</tr>
								</table>
								<table id="selections-table" *ngIf="selectedLanguageType !== 'sql'">
									<tr>
										<td>
											<p>Tab Size [Default Value is 4]</p>
											<mat-checkbox [(ngModel)]="options['indent_with_tabs']">
												Intend with tab
											</mat-checkbox>
											<mat-slider min="4" max="32" step="2" thumbLabel tickInterval="2" [disabled]="options['indent_with_tabs']" 
											[(ngModel)]="options['indent_with_tabs'] ? 4 : options['indent_size']" name="tabsize" id="tabsize">
											</mat-slider>
											<br>
											<p>New Line</p>
											<mat-checkbox [(ngModel)]="options['preserve_newlines']">
												Preserve new lines
											</mat-checkbox>
											<mat-slider min="0" max="10" step="1" thumbLabel tickInterval="1" [disabled]="!options['preserve_newlines']" 
											[(ngModel)]="options['max_preserve_newlines']" name="max-preserve-newlines" id="max-preserve-newlines">
											</mat-slider>
											<br>
											<p>Wrap lines that exceed N characters [Default Value is 0]</p>
											<mat-slider min="0" max="160" step="10" thumbLabel tickInterval="10" [(ngModel)]="options['wrap_line_length']" 
											name="wrap-line-length" id="wrap-line-length">
											</mat-slider>
											<br>
											<p>Initial Indentation Level [Default Value is 0]</p>
											<mat-slider min="0" max="100" step="10" thumbLabel tickInterval="10" [(ngModel)]="options['indent_level']" 
											name="indent-level-length" id="indent-level-length">
											</mat-slider>
											<br>
											<p>HTML &lt;style&gt;, &lt;script&gt; formatting:</p>
											<select class="form-select {{darkMode ? 'dark-theme' : ''}}" aria-label="HTML &lt;style&gt;, &lt;script&gt; formatting" [(ngModel)]="options['indent_scripts']" id="indent-scripts">
												<option value="keep">Keep indent level of the tag</option>
												<option value="normal">Add one indent level</option>
												<option value="separate">Separate indentation</option>
											</select>
											
											<!-- <mat-select class="select" [(ngModel)]="options['indent_scripts']" panelClass="{{darkMode ? 'dark-theme' : ''}}" id="indent-scripts">
												<mat-option value="keep">Keep indent level of the tag</mat-option>
												<mat-option value="normal">Add one indent level</mat-option>
												<mat-option value="separate">Separate indentation</mat-option>
											</mat-select> -->
											<br>
											<p>Brace Styles</p>
											<select class="form-select {{darkMode ? 'dark-theme' : ''}}" aria-label="Brace Styles" [(ngModel)]="options['brace_style']" id="brace-style">
												<option value="collapse">Braces with control statement</option>
												<option value="expand">Braces on own line</option>
												<option value="end-expand">End braces on own line</option>
												<option value="none">Attempt to keep braces where they are</option>
											</select>
											
											<!-- <mat-select class="select" [(ngModel)]="options['brace_style']" panelClass="{{darkMode ? 'dark-theme' : ''}}" id="brace-style">
												<mat-option value="collapse">Braces with control statement</mat-option>
												<mat-option value="expand">Braces on own line</mat-option>
												<mat-option value="end-expand">End braces on own line</mat-option>
												<mat-option value="none">Attempt to keep braces where they are</mat-option>
											</mat-select> -->
											<br>
											<p>Operator Position</p>
											<select class="form-select {{darkMode ? 'dark-theme' : ''}}" aria-label="Operator Position" [(ngModel)]="options['operator_position']" id="operator-style">
												<option value="before-newline">Before Newline</option>
												<option value="after-newline">After Newline</option>
												<option value="preserve-newline">Preserve Newline</option>
											</select>
											
											<!-- <mat-select class="select" [(ngModel)]="options['operator_position']" panelClass="{{darkMode ? 'dark-theme' : ''}}" id="operator-style">
												<mat-option value="before-newline">Before Newline</mat-option>
												<mat-option value="after-newline">After Newline</mat-option>
												<mat-option value="preserve-newline">Preserve Newline</mat-option>
											</mat-select> -->
										</td>
										<td>
											<p>Options</p>
											<mat-checkbox class="options-checkbox" [(ngModel)]="options['space_in_paren']">
												Add padding spaces within paren, ie. f( a, b )
											</mat-checkbox>
											<br>
											<mat-checkbox class="options-checkbox" [(ngModel)]="options['space_in_empty_paren']">
												Add a single space inside empty paren, ie. f( )
											</mat-checkbox>
											<br>
											<mat-checkbox class="options-checkbox" [(ngModel)]="options['editorconfig']">
												Use EditorConfig to set up the options
											</mat-checkbox>
											<br>
											<mat-checkbox class="options-checkbox" [(ngModel)]="options['space_after_anon_function']">
												Add a space before an anonymous function's parens, ie. function ()
											</mat-checkbox>
											<br>
											<mat-checkbox class="options-checkbox" [(ngModel)]="options['space_after_named_function']">
												Add a space before a named function's parens, i.e. function example ()
											</mat-checkbox>
											<br>
											<mat-checkbox class="options-checkbox" [(ngModel)]="options['e4x']">
												Support e4x/jsx syntax
											</mat-checkbox>
											<br>
											<mat-checkbox class="options-checkbox" [(ngModel)]="options['comma_first']">
												Use comma-first list style?
											</mat-checkbox>
											<br>
											<mat-checkbox class="options-checkbox" [(ngModel)]="options['keep_array_indentation']">
												Keep array indentation?
											</mat-checkbox>
											<br>
											<mat-checkbox class="options-checkbox" [(ngModel)]="options['break_chained_methods']">
												Break lines on chained methods?
											</mat-checkbox>
											<br>
											<mat-checkbox class="options-checkbox" [(ngModel)]="options['space_before_conditional']">
												Space before conditional: "if(x)" / "if (x)"
											</mat-checkbox>
											<br>
											<mat-checkbox class="options-checkbox" [(ngModel)]="options['unescape_strings']">
												Unescape printable chars encoded as \xNN or \uNNNN?
											</mat-checkbox>
											<br>
											<mat-checkbox class="options-checkbox" [(ngModel)]="options['jslint_happy']">
												Use JSLint-happy formatting tweaks?
											</mat-checkbox>
											<br>
											<mat-checkbox class="options-checkbox" [(ngModel)]="options['indent-inner-html']">
												Indent &lt;head&gt; and &lt;body&gt; sections?
											</mat-checkbox>
										</td>
										<td>
											<button mat-raised-button color="warn" class="default mb-3" (click)="resetDefault()" style="width: 100%;">
												Reset to Default
											</button>
											<p>Your Selected Options (JSON):</p>
											<textarea id="options" spellcheck="false" [ngModel]="options | json" (ngModelChange)="validateJSON($event)" rows="29">
												{{options | json}}
											</textarea>
											<!-- <button mat-raised-button color="warn" class="default" (click)="resetDefault()" style="width: 100%;">
												Reset to Default
											</button> -->
										</td>
									</tr>
								</table>
							</div>
							<!-- <div class="modal-footer">
							<button class="btn btn-primary" (click)="beautify()" style="text-transform: uppercase;">
								Beautify {{selectedLanguageType}}
							</button>
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							</div> -->
						</div>
						</div>
					</div>
					<button class="btn btn-primary" (click)="beautify()" style="text-transform: uppercase;">
						Beautify {{selectedLanguageType}}
					</button>
				</div>
				<button class="btn btn-secondary" (click)="clearStorageOptions()" style="text-transform: uppercase;">
					Reset Storage
				</button>
			</div>
		</div>
		<mat-card id="code-area" style="min-height: 450px;">
            <ngx-codemirror [(ngModel)]="code" [autoFocus]="true" [options]="codeOptions" 
                (ngModelChange)="setLocalStorage('code', code)">
            </ngx-codemirror>
		</mat-card>
		<!-- <button mat-raised-button color="primary" class="beautify" (click)="beautify()" style="text-transform: uppercase;">
            Beautify {{selectedLanguageType}} Code
        </button> -->
	</div>
</div>
